import { useState, FC, Fragment, useEffect } from "react";
import { ReactComponent as ExitIcon } from "@images/exit_icon.svg";
import { useHotkeys } from "react-hotkeys-hook";
import IconButton from "@components/common/IconButton";
import Alert, { AlertState } from "@components/common/Alert";
import { Dialog, Input, Transition } from "@headlessui/react";
import OtpInput from "react-otp-input";
import { postBudget, deleteBudget } from "@utils/managers/networking/NetworkManager";
import { useNavigate } from "react-router-dom";
import { generateToast } from "@utils/managers/ToastManager";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import { PUBGrowCampaign } from "@models/Models";

interface BudgetModalProps {
	selectedCampaign: PUBGrowCampaign;
	setSelectedCampaign: (campaign: PUBGrowCampaign) => void;
	isShow: boolean;
	onClose: () => void;
}

const BudgetModal: FC<BudgetModalProps> = ({ selectedCampaign, setSelectedCampaign, isShow, onClose }) => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [budget, setBudget] = useState<string>(selectedCampaign.budget ?? "");
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [isValid, setValid] = useState<boolean>(false);

	useHotkeys("Escape", () => onModalClose());

	useEffect(() => {
		if (isShow) {
			console.log(selectedCampaign.budget);
			setBudget(selectedCampaign.budget ?? "");
		}
	}, [isShow]);

	useEffect(() => {
		const isValidBudget = budget.match(/^\$\d+(\.\d{1,2})?$/);
		setValid(isValidBudget !== null);
	}, [budget]);

	const onModalClose = () => {
		onClose();
		setTimeout(() => {
			setBudget("");
			setLoading(false);
            setDeleteLoading(false);
		}, 300);
	};

	const inputOnChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		let fieldValue: string = e.currentTarget.value;
		fieldValue = fieldValue.replace(/[^0-9.]/g, "");

		const decimalCount = (fieldValue.match(/\./g) || []).length;
		if (decimalCount > 1) {
			return;
		}

		const [integerPart, decimalPart] = fieldValue.split(".");
		let formattedValue = integerPart;

		if (decimalPart !== undefined) {
			formattedValue += "." + decimalPart.slice(0, 2);
		}

		if (formattedValue === "0") {
			return;
		}

		if (formattedValue.length > 6) {
			return;
		}

		setBudget("$" + formattedValue);
	};

	const budgetOnChange = () => {
		setLoading(true);
		recordEvent(kAnalyticsConstants.Grow.budgetChanged);

		if (!isValid) return;
		const newBudget = budget.replace("$", "");
		const formattedBudget = parseFloat(newBudget).toFixed(2);

		postBudget(selectedCampaign.id, formattedBudget)
			.then(() => {
				var tempSelectedCampaign = { ...selectedCampaign };
				tempSelectedCampaign.budget = `$${formattedBudget}`;
				setSelectedCampaign(tempSelectedCampaign);
				setLoading(false);
				generateToast({ status: "success", title: "Campaign budget updated" });
				onModalClose();
			})
			.catch((_) => {
				setLoading(false);
				generateToast({ status: "error", title: "Something went wrong", message: "We couldn't update your campaign budget. Please try again later or contact us." });
			});
	};

	const budgetOnDelete = () => {
		setDeleteLoading(true);
		recordEvent(kAnalyticsConstants.Grow.budgetDeleted);
		deleteBudget(selectedCampaign.id)
			.then(() => {
                var tempSelectedCampaign = { ...selectedCampaign };
                tempSelectedCampaign.budget = null;
                setSelectedCampaign(tempSelectedCampaign);
                setLoading(false);
                generateToast({ status: "success", title: "Campaign budget deleted" });
                onModalClose();
			})
			.catch((_) => {
				setDeleteLoading(false);
				generateToast({ status: "error", title: "Something went wrong", message: "We couldn't delete your campaign budget. Please try again later or contact us." });
			});
	};

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<Transition as={Fragment} show={isShow}>
				<Dialog onClose={() => onClose()} className="relative z-40">
					<Transition.Child as="div" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-70" aria-hidden="true" />
					</Transition.Child>
					<div className="fixed inset-0 flex w-screen items-center justify-center p-4">
						<Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="max-w-[400px] transform overflow-hidden rounded-2xl bg-secondary border border-white/10 text-left align-middle shadow-xl transition-all p-4">
								<div className="flex flex-col gap-2">
									<div className="w-full flex flex-row justify-between items-center pb-2">
										<div className="text-primary font-medium font-primary text-xl">Set a monthly budget</div>
										<IconButton Icon={ExitIcon} className="-mr-2.5 hover:bg-primary/20" iconClassName="fill-primary" onClick={() => onModalClose()} />
									</div>
									<div className="flex flex-col gap-4 text-center h-full w-full">
										<div className="text-primary font-regular font-primary text-sm text-left">Setting a budget helps you manage your monthly spending and ensures you are never charged more than your set limit. If you’ve already exceeded the budget you’re about to set, it will take effect next month.</div>
										<form onSubmit={() => budgetOnChange()}>
											<div className="flex flex-col gap-4 flex-grow">
												<Input type="text" disabled={isLoading} id="monthly_budget" autoFocus={true} value={budget} onChange={inputOnChange} className={"block w-full rounded-[12px] bg-primary/5 py-1.5 px-3 text-lg/6 text-primary focus:outline-none focus:outline-2 focus:-outline-offset-2 focus:outline-primary-200 border border-primary-200"} placeholder="Monthly budget ($)" />
												<div className="flex flex-col gap-3">
													<button type="submit" disabled={!isValid || isLoading} className={`bg-success-green font-primary text-lg text-white rounded-xl shadow-sm w-full h-[44px] ${isValid ? "opacity-100" : "opacity-50"}`} onClick={() => budgetOnChange()}>
														{isLoading ? <LoadingSpinner className="m-auto h-[20px] text-white/20 animate-spin fill-white" /> : <div>Set budget</div>}
													</button>
													<button disabled={selectedCampaign.budget === null || isDeleteLoading} className={`transition ease-in-out duration-300 border border-brand-red font-primary text-lg text-brand-red rounded-xl w-full h-[44px] ${selectedCampaign.budget !== null && !isDeleteLoading ? "opacity-100 hover:bg-brand-red hover:text-white" : "opacity-50"}`} onClick={() => budgetOnDelete()}>
														{isDeleteLoading ? <LoadingSpinner className="m-auto h-[20px] text-brand-red/20 animate-spin fill-brand-red" /> : <div>Delete budget</div>}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default BudgetModal;
