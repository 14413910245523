import { PUBBAffiliateOverviewStatsResponse, PUBBReferralOverviewStat, PUBGrowCampaign, PUBGrowDetail } from "@models/Models";
import { useAuth } from "@providers/AuthContext";
import * as networkManager from "@utils/managers/networking/NetworkManager";
import { generateToast } from "@utils/managers/ToastManager";
import { useEffect, useState } from "react";
import { DateRangePicker, DateRangePickerValue, Flex, Icon, Metric, TableBody, TableCell, TableRow, Title, BarChart, LineChart, Badge, Table } from "@tremor/react";
import { ReactComponent as LoadingSpinner } from "@images/loading_spinner.svg";
import SectionLayout from "../../layouts/SectionLayout";
import Tooltip from "../../utils/Tooltip";
import TitleHeader from "@components/common/TitleHeader";
import moment from "moment";
import { CampaignType, OverviewStatType } from "@models/Enums";
import { BanknotesIcon, BeakerIcon, ChartBarIcon, ChartPieIcon, CheckIcon, CurrencyDollarIcon, ExclamationTriangleIcon, InformationCircleIcon, PauseIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { kAppConstants } from "@utils/constants/AppConstants.js";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { ReactComponent as ListIcon } from "@images/list_icon.svg";
import { ReactComponent as CheckmarkIcon } from "@images/checmark_raw_icon.svg";
import { recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import BudgetModal from "./BudgetModal";

const GrowDashboard = () => {
	const { authUser, activeNewsletter } = useAuth();
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isPerformanceLoading, setPerformanceLoading] = useState<boolean>(true);
	const [selectedMonthData, setSelectedMonthData] = useState<DateRangePickerValue>({ selectValue: "t" });
	const [overviewStatResponse, setOverviewStatResponse] = useState<PUBBAffiliateOverviewStatsResponse | null>(null);
	const [isMetricLoading, setIsMetricLoading] = useState<boolean>(false);
	const [selectedCampaign, setSelectedCampaign] = useState<PUBGrowCampaign | undefined>(undefined);
	const [affiliateDetail, setAffiliateDetail] = useState<PUBGrowDetail | null>(null);
	const [isBudgetModalOpen, setBudgetModal] = useState(false);

	useEffect(() => {
		recordEvent(kAnalyticsConstants.Grow.dashboardClicked);
		getCampaigns();
	}, []);

	useEffect(() => {
		if (selectedCampaign) {
			getCampaignStats(selectedCampaign);
		}
	}, [selectedCampaign]);

	const getCampaigns = () => {
		networkManager
			.getGrowDetail(activeNewsletter!.id.toString())
			.then((affiliateDetail) => {
				setAffiliateDetail(affiliateDetail);
				setInitialCampaign(affiliateDetail);
				setLoading(false);
			})
			.catch((_) => {
				setLoading(false);
				setPerformanceLoading(false);
				generateToast({ status: "error", title: "Something went wrong.", message: "Please try again later or contact us." });
			});
	};

	const setInitialCampaign = (affiliateDetail: PUBGrowDetail) => {
		var tempUserSelectedCampaign = selectedCampaign;
		if (!tempUserSelectedCampaign) {
			// If no campaign is already selected, use the latest campaign
			const latestCampaign = affiliateDetail.campaigns.reduce((latest, campaign) => {
				if (!campaign.active_from) return latest; // Skip if no date
				if (!latest.active_from) return campaign; // If latest has no date, use the current campaign
				return new Date(campaign.active_from) > new Date(latest.active_from) ? campaign : latest;
			}, affiliateDetail.campaigns[0]);
			tempUserSelectedCampaign = latestCampaign;
		}
		setSelectedCampaign(tempUserSelectedCampaign);
	};

	const getCampaignStats = (campaign: PUBGrowCampaign) => {
		setPerformanceLoading(true);
		const defaultQuery = getDefaultQueryDate(campaign.active_from);
		setSelectedMonthData(defaultQuery);
		networkManager
			.getAffiliateOverviewStats(campaign.id, defaultQuery.from!, defaultQuery.to!)
			.then((affiliateOverviewResponse) => {
				setOverviewStatResponse(affiliateOverviewResponse);
				setPerformanceLoading(false);
			})
			.catch((_) => {
				setPerformanceLoading(false);
				generateToast({ status: "error", title: "Something went wrong.", message: "We couldn't get your stats. Please try again later or contact us." });
			});
	};

	const getDefaultQueryDate = (activeFromString?: string): DateRangePickerValue => {
		var momentFromDate = moment().subtract(30, "d");
		var fromDate = momentFromDate.toDate();

		var toDate = new Date();

		if (activeFromString) {
			if (momentFromDate.isBefore(activeFromString)) {
				fromDate = new Date(activeFromString);
			}
		}
		return { from: fromDate, to: toDate, selectValue: "t" };
	};

	const onPickerDateChange = (dateData) => {
		setSelectedMonthData({
			...dateData,
		});

		if (dateData.from === undefined || dateData.to === undefined) {
			return;
		}

		if (dateData) {
			setIsMetricLoading(true);
			networkManager
				.getAffiliateOverviewStats(selectedCampaign!.id, dateData.from, dateData.to)
				.then((affiliateOverviewResponse) => {
					setOverviewStatResponse(affiliateOverviewResponse);
					setIsMetricLoading(false);
				})
				.catch((_error) => {
					generateToast({ status: "error", title: "Something went wrong.", message: "We couldn't load your stats. Please try again later." });
				});
		}
	};

	const renderLoadingSpinner = () => {
		return <LoadingSpinner className="w-[30px] text-primary-200 animate-spin fill-primary my-3" />;
	};

	const getCardIcon = (statId) => {
		switch (statId) {
			case "new_subscribers":
			case "conversion":
				return UserGroupIcon;
			case "amount_spent":
				return BanknotesIcon;
			case "new_subscribers_graph":
			case "new_engaged_subscribers_graph":
			case "daily_cohorts":
			case "cumulative_subscribers_graph":
			case "cumulative_new_conversions_graph":
				return ChartBarIcon;
			default:
				return CheckIcon;
		}
	};

	const getEngagementFieldColor = (field: string) => {
		console.log(field);
		if (field.includes("Engaged")) {
			return "text-green-500 indent-[12px] font-medium";
		}

		if (field.includes("Screening in progress")) {
			return "text-primary-500 indent-[12px]";
		}

		if (field.includes("Unengaged")) {
			return "text-yellow-500 indent-[12px]";
		}

		return "text-primary";
	};

	const renderMetric = (stat: PUBBReferralOverviewStat) => {
		switch (stat.type) {
			case OverviewStatType.Table:
				return (
					<div key={stat.id} className="sm:col-span-4 lg:col-span-2 col-span-2 border-primary-100 bg-surface border duration-300 transition ease-in-out rounded-[15px] p-3 min-w-[200px]">
						<Flex alignItems="start">
							<div className="w-full truncate mt-2">
								<div className="flex flex-row items-center gap-3">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{isMetricLoading ? (
									renderLoadingSpinner()
								) : (
									<Table className="w-full mt-3">
										<TableBody>
											{stat.data_points.map((dataPoint, index) => {
												return (
													<TableRow>
														<TableCell className={`font-primary text-primary text-base ${getEngagementFieldColor(dataPoint.date)}`}>{dataPoint.date}</TableCell>
														<TableCell>{dataPoint.value}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								)}
							</div>
							{stat.status && (
								<div>
									<Badge size="md" color={stat.status_color}>
										{stat.status.toUpperCase()}
									</Badge>
								</div>
							)}
							{stat.hint && (
								<div>
									<Tooltip message={stat.hint}>
										<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
									</Tooltip>
								</div>
							)}
						</Flex>
					</div>
				);
			case OverviewStatType.Metric:
				return (
					<div key={stat.id} className="sm:col-span-4 lg:col-span-2 col-span-2 border-primary-100 bg-surface border duration-300 transition ease-in-out rounded-[15px] p-3 min-w-[200px]">
						<div className="flex flex-col items-start h-full justify-between gap-2">
							<div>
								<div>
									<div className="flex flex-row items-center gap-3 mt-2">
										<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
										<Title>{stat.title}</Title>
									</div>
									<div className="mt-3">{isMetricLoading ? renderLoadingSpinner() : <Metric>{stat.value}</Metric>}</div>
								</div>
								{stat.status && (
									<div>
										<Badge size="md" color={stat.status_color}>
											{stat.status.toUpperCase()}
										</Badge>
									</div>
								)}
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							{stat.id === "amount_spent" && selectedCampaign?.type == CampaignType.Engaged && <div className="font-primary text-primary-500 text-base">With pay per engaged subscriber campaigns, you only pay for readers who actively engage with your newsletter. When a Meco reader opts in to your newsletter, they enter a 7-day screening period where they’ll receive your emails just like a subscriber. During this time, they’ll get your latest issue and any new content you send. If they open at least one email and remain subscribed by the end of the 7 days, they’ll be automatically added to your subscriber list, and you’ll be charged for them.</div>}
						</div>
					</div>
				);
			case OverviewStatType.Bar:
				return (
					<div key={stat.id} className="sm:col-span-4 col-span-2 border-primary-100 bg-surface border duration-300 transition ease-in-out rounded-[15px] p-3 min-w-[200px]">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <BarChart data={stat.data_points} index="date" categories={[selectedCampaign?.type == CampaignType.Engaged ? "Cumulative Engaged Subscribers" : "Cumulative New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</div>
				);
			case OverviewStatType.Line:
				return (
					<div key={stat.id} className="sm:col-span-4 col-span-2 border-primary-100 bg-surface border duration-300 transition ease-in-out rounded-[15px] p-3 min-w-[200px]">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <LineChart data={stat.data_points} index="date" categories={["New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</div>
				);
			case OverviewStatType.Stack:
				return (
					<div key={stat.id} className="sm:col-span-4 col-span-2 border-primary-100 bg-surface border duration-300 transition ease-in-out rounded-[15px] p-3 min-w-[200px]">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <BarChart data={stat.data_points} index="date" categories={["Screening", "Unengaged", "Engaged"]} colors={["gray", "yellow", "green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} stack={true} />}</div>
						</div>
					</div>
				);
		}
	};

	const getStripePortalAddress = () => {
		return kAppConstants.stripeCustomerPortalUrl + encodeURI(authUser!.email);
	};

	return (
		<>
			{isLoading ? (
				<div className="w-full h-[100vh] flex items-center justify-center">
					<LoadingSpinner className="w-[40px] text-primary-200 animate-spin fill-primary" />
				</div>
			) : (
				<>
					<TitleHeader title="Grow with Meco" />
					<div className="container mx-auto px-3 py-3 sm:px-12 sm:py-12">
						<div className="flex flex-col gap-5 m-auto">
							<SectionLayout
								id="stats"
								sectionTitle="Campaigns"
								sectionRightDiv={
									affiliateDetail &&
									affiliateDetail.campaigns.length > 1 && (
										<Listbox value={selectedCampaign} onChange={setSelectedCampaign}>
											<ListboxButton className={"relative block w-[200px] rounded-lg py-1.5 pr-8 pl-3 text-left text-sm/6 text-primary focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 border border-primary-200 font-primary font-regular"}>
												All campaigns <ListIcon className="group pointer-events-none absolute top-[12px] right-2.5 size-3 fill-primary-500" />
											</ListboxButton>
											<ListboxOptions anchor="bottom" className={"rounded-lg"}>
												{affiliateDetail!.campaigns.map((campaign) => (
													<ListboxOption key={campaign.id} value={campaign} className="group flex items-center gap-2  py-2 px-3 select-none bg-secondary hover:bg-success-green cursor-pointer w-[200px] font-primary font-regular text-primary hover:text-white">
														<CheckmarkIcon className="invisible size-[12px] fill-success-green group-data-[selected]:visible" />
														{campaign.id}
														{campaign.is_paused ? " (paused)" : ""}
													</ListboxOption>
												))}
											</ListboxOptions>
										</Listbox>
									)
								}>
								{affiliateDetail && !affiliateDetail.cc_on_file && (
									<div className="w-full border-[1px] bg-red-600 border-card rounded-[10px] p-[20px] font-regular font-primary text-base text-white flex flex-row items-center gap-3">
										<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
										<div>
											<a href={getStripePortalAddress()} target="_blank" className="text-white">
												Add your card
											</a>{" "}
											information to prevent any disruptions to your campaign.
										</div>
									</div>
								)}
								{selectedCampaign && (
									<>
										<BudgetModal isShow={isBudgetModalOpen} onClose={() => setBudgetModal(false)} selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign}/>
										<div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
											{!selectedCampaign.is_paused ? (
												<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-primary-100">
													<Icon icon={CheckIcon} color="green" variant="light" size="sm" />
													<div className="font-primary text-primary text-sm flex flex-col">
														<div className="font-medium text-sm opacity-50">STATUS</div> Active
													</div>
												</div>
											) : (
												<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-primary-100">
													<Icon icon={PauseIcon} color="yellow" variant="light" size="sm" />
													<div className="font-primary text-primary text-sm flex flex-col">
														<div className="font-medium text-sm opacity-50">STATUS</div> Paused
													</div>
												</div>
											)}
											<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-primary-100">
												<Icon icon={BeakerIcon} color="green" variant="light" size="sm" />
												<div className="font-primary text-primary text-sm flex flex-col">
													<div className="font-medium text-sm opacity-50">PRICING MODEL</div>
													<div className="font-primary text-primary text-sm flex flex-row">
														Per&nbsp;
														{selectedCampaign.type == CampaignType.Engaged ? (
															<Tooltip message={"With this pricing model, you only pay for engaged subscribers—those who open at least one email and stay subscribed during their first 7 days."}>
																<div className="underline cursor-pointer">engaged subscriber</div>
															</Tooltip>
														) : (
															"subscriber"
														)}
													</div>
												</div>
											</div>
											<div className="flex flex-row items-center justify-between gap-3 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-primary-100">
												<div className="flex items-center gap-3">
													<Icon icon={ChartPieIcon} color="green" variant="light" size="sm" className="h-[32px]" />
													<div className="font-primary text-primary text-sm flex flex-col">
														<div className="font-medium text-sm opacity-50">MONTHLY BUDGET</div>
														{selectedCampaign.budget ? (
															<Tooltip message={"This is the maximum amount you’ll be charged each month. If your spend exceeds this budget, you’ll still receive all the extra subscribers for free."} isCenter={false}>
																<div className="underline cursor-pointer">{selectedCampaign?.budget}</div>
															</Tooltip>
														) : (
															"N/A"
														)}
													</div>
												</div>
												<button onClick={() => setBudgetModal(true)} className="font-primary text-primary text-sm flex flex-row bg-primary/20 hover:bg-primary hover:text-secondary duration-300 transition ease-in-out rounded-lg px-3 py-1">
													{selectedCampaign.budget ? "Edit" : "Set"}
												</button>
											</div>
											{selectedCampaign.active_price && (
												<div className="flex flex-row items-center gap-3 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-primary-100">
													<Icon icon={CurrencyDollarIcon} color="green" variant="light" size="sm" />
													<div className="font-primary text-primary text-sm flex flex-col">
														<div className="font-medium text-sm opacity-50">CPA</div> {selectedCampaign?.active_price}
													</div>
												</div>
											)}
										</div>
									</>
								)}
							</SectionLayout>
							<SectionLayout
								id="performance"
								sectionTitle="Performance"
								sectionRightDiv={
									<div>
										<DateRangePicker value={selectedMonthData} weekStartsOn={1} minDate={selectedCampaign?.active_from ? new Date(selectedCampaign?.active_from) : new Date()} maxDate={new Date()} enableClear={false} onValueChange={(dateData) => onPickerDateChange(dateData)} className="justify-start w-auto sm:justify-end" />
									</div>
								}>
								{isPerformanceLoading ? (
									<div className="flex flex-row items-center justify-center">{renderLoadingSpinner()}</div>
								) : (
									<div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
										{overviewStatResponse &&
											overviewStatResponse.overview_stats
												.sort((a, b) => a.sort_index - b.sort_index)
												.map((stat) => {
													return renderMetric(stat);
												})}
									</div>
								)}
							</SectionLayout>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default GrowDashboard;
