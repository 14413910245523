import { kLocalStorageKeys } from "../../constants/kLocalStorageKeys";

export const Production_Base_URL = "https://www.meco.app"
export const Stage_Base_URL = "https://stage.meco.app"

export function baseURL() {
    if (process.env.REACT_APP_STAGE === 'production') {
        return Production_Base_URL
    } else {
        return Stage_Base_URL
    }
}

export function redirectUri() {
    return process.env.REACT_APP_GOOGLE_REDIRECT_URI;
}

export const Endpoints = {
    signUpUser: '/api/publisher/sign-up',
    logInUser: '/api/publisher/log-in',
    validateMagicLink: '/api/auth/magic-link',
    tempAuthToken: '/api/publisher/auth',
    subscriberList: '/api/publisher/subscriber-list',
    userProfile: '/api/publisher/profile',
    alerts: '/api/publisher/alerts',
    integrations: '/api/publisher/integrations',
    getReferralLink: '/api/publisher/referral/get/',
    createReferralLink: '/api/publisher/referral/create/',
    getReferralOverviewStats: '/api/publisher/referral/overview-stats/',
    getReferralHistory: '/api/publisher/referral/history/',
    postPaypalEmail: '/api/publisher/referral/paypal-account/',
    postWiseData: '/api/publisher/referral/wise-account/',
    getAffiliateDetail: '/api/publisher/grow/get/',
    getAffiliateOverviewStats: '/api/publisher/grow/overview-stats/',
    logOutUser: '/api/log-out',
    validateMagicLink: '/api/auth/magic-link',
    validateMagicCode: '/api/auth/magic-code',
    subscriberExportInfo: '/api/publisher/subscriber-export-info',
    subscriberExport: '/api/publisher/subscriber-export',
    postBudget: '/api/publisher/budget',
}

export function getUserAuthToken() {
    return localStorage.getItem(kLocalStorageKeys.User.token);
}