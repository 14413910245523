import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { ShaderGradientCanvas, ShaderGradient } from 'shadergradient'

const ParticlesBackground: React.FC = () => {
	return (
		<ShaderGradientCanvas
		style={{
		  position: 'fixed',
		  top: 0,
		  pointerEvents: "none",
		}}
	  >
		<ShaderGradient
		  control='query'
		  urlString='https://www.shadergradient.co/customize?animate=on&axesHelper=on&bgColor1=%23000000&bgColor2=%23000000&brightness=1.1&cAzimuthAngle=180&cDistance=3.9&cPolarAngle=115&cameraZoom=3&color1=%23191D2E&color2=%23191D2E&color3=%23000000&destination=onCanvas&embedMode=off&envPreset=city&format=gif&fov=45&frameRate=10&grain=off&lightType=3d&pixelDensity=3&positionX=-0.5&positionY=0.1&positionZ=0&range=enabled&rangeEnd=40&rangeStart=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=235&shader=positionMix&type=sphere&uAmplitude=0&uDensity=1&uFrequency=5.5&uSpeed=0.1&uStrength=10&uTime=0.2&wireframe=false'
		/>
	  </ShaderGradientCanvas>
	);
};

export default React.memo(ParticlesBackground);
