export class PUBProfile {
	id!: string;
	first_name?: string;
	last_name?: string;
	email!: string;
	newsletters: PUBNewsletter[] = [];
	alerts!: PUBAlert;

	constructor(profile: PUBProfile) {
		Object.assign(this, profile);

		if (profile.newsletters) {
			this.newsletters = profile.newsletters.map((newsletter: PUBNewsletter) => new PUBNewsletter(newsletter));
		}
	}
}

export class PUBNewsletter {
	id: number = 0;
	name?: string;
	summary?: string;
	image_url?: string;
	subscribe_link?: string;
	website?: string;
	whitelisted_date?: string;
	unsubscribe_link?: string;
	logo?: string;
	is_whitelisted?: boolean;
	domain?: string;
	type?: string[];
	category?: PUBNewsletterCategory[];
	sub_category?: PUBNewsletterCategory[];
	is_discoverable?: boolean;
	is_recommended?: boolean;
	discover_sort_index?: number;
	onboarding_sort_index?: number;
	onboarding_force_priority_level?: number;
	badge?: 0;
	one_click_subscribe_method?: boolean;
	is_referral_active: boolean = false;
	is_affiliate_active: boolean = false;
	paypal_account?: string;
	wise_email?: string;
	wise_currency?: string;
	wise_account_type?: string;

	constructor(newsletter: PUBNewsletter) {
		Object.assign(this, newsletter);
	}
}

export class PUBNewsletterCategory {
	id!: number;
	name?: string;
	emoji?: string;
	sort_index?: number;
	image_url?: string;
	is_user_picked?: boolean;

	constructor(newsletterCategory: NLNewsletterCategory) {
		Object.assign(this, newsletterCategory);
	}
}

export class PUBAlert {
	weekly_summary?: number;
	daily_summary?: boolean;
	instant?: boolean;

	constructor(alert: PUBAlert) {
		Object.assign(this, alert);
	}
}

export class PUBReferralDetail {
	referral_url!: string;
	active_from!: string;
	active_price!: string;
	is_paused!: boolean;

	constructor(referralDetail: PUBReferralDetail) {
		Object.assign(this, referralDetail);
	}
}

export class PUBBReferralOverviewStatsResponse {
	date!: string;
	overview_stats!: PUBBReferralOverviewStat[];

	constructor(overviewStatsResponse: PUBBReferralOverviewStatsResponse) {
		Object.assign(this, overviewStatsResponse);

		if (overviewStatsResponse.overview_stats) {
			this.overview_stats = overviewStatsResponse.overview_stats.map((overview_stats: PUBBReferralOverviewStat) => new PUBBReferralOverviewStat(overview_stats));
		}
	}
}

export class PUBBReferralOverviewStat {
	id!: string;
	sort_index!: number;
	title!: string;
	value?: string;
	status?: string;
	status_color?: string;
	hint?: string;
	type: OverviewStatType;
	data_points: OverviewStatDataPoint[];
	note?: string;

	constructor(overviewStats: PUBBReferralOverviewStat) {
		this.sort_index = overviewStats.sort_index !== undefined ? overviewStats.sort_index : 0;
		this.type = overviewStats.type || OverviewStatType.Metric;
		this.data_points = overviewStats.data_points || [];
		Object.assign(this, overviewStats);
	}
}

export enum OverviewStatType {
	Table = "table",
	Metric = "metric",
	Bar = "bar",
	Line = "line",
	Stack = "stack",
}

export type OverviewStatDataPoint = {
	date: string;
	value?: number;
};

export class PUBGrowCampaign {
	id!: string;
	type!: string;
	name!: string;
	active_price?: string;
	active_from?: string;
	is_paused!: boolean;
	budget?: string | null;
	engaged_window?: number;

	constructor(campaign: PUBGrowCampaign) {
		Object.assign(this, campaign);
	}
}

export class PUBGrowDetail {
	campaigns!: PUBGrowCampaign[];
	cc_on_file!: boolean;

	constructor(growDetail: PUBGrowDetail) {
		Object.assign(this, growDetail);

		this.campaigns = growDetail.campaigns.map((campaign: PUBGrowCampaign) => new PUBGrowCampaign(campaign));
	}
}

export class PUBBAffiliateOverviewStatsResponse {
	start_date!: string;
	end_date!: string;
	overview_stats!: PUBBReferralOverviewStat[];

	constructor(overviewStatsResponse: PUBBAffiliateOverviewStatsResponse) {
		Object.assign(this, overviewStatsResponse);

		if (overviewStatsResponse.overview_stats) {
			this.overview_stats = overviewStatsResponse.overview_stats.map((overview_stats: PUBBReferralOverviewStat) => new PUBBReferralOverviewStat(overview_stats));
		}
	}
}

export class NLNewsletterCategory {
	id!: number;
	name?: string;
	emoji?: string;
	sort_index?: number;
	image_url?: string;
	is_user_picked?: boolean;

	constructor(newsletterCategory: NLNewsletterCategory) {
		Object.assign(this, newsletterCategory);
	}
}

export interface NLWallpaper {
	imageId: number;
	image: string;
	sidebarColor: string;
	containerColor: string;
}

export interface PUBIntegration {
	id: number;
	name: string;
	sort_index: number;
	fields: string[];
	notice_html: string;
}

export interface PUBNewsletterIntegration {
	integration_id: number;
	fields: Record<string, string>;
	error: boolean;
}

export class PUBIntegrationsResponse {
	integrations: PUBIntegration[];
	newsletter_integration?: PUBNewsletterIntegration;
	zapierKey?: string;

	constructor(data: { integrations: PUBIntegration[]; newsletter_integration: PUBNewsletterIntegration | undefined; zapierKey: string }) {
		this.integrations = data.integrations.map((integration) => ({
			id: integration.id,
			name: integration.name,
			sort_index: integration.sort_index,
			fields: integration.fields,
			notice_html: integration.notice_html,
		}));
		if (data.newsletter_integration) {
			this.newsletter_integration = {
				integration_id: data.newsletter_integration.integration_id,
				fields: data.newsletter_integration.fields,
				error: data.newsletter_integration.error,
			};
		}

		this.zapierKey = data.zapierKey;
	}
}


export class PUBSubscriberListResponse {
	count!: number;
	next?: string;
	previous?: string;
	results!: {
		last_csv_export?: string;
		subscriber_list: PUBSubscriber[];
	}

	constructor(subscriberListResponse: PUBSubscriberListResponse) {
		Object.assign(this, subscriberListResponse);
		this.results.subscriber_list = subscriberListResponse.results.subscriber_list.map((subscriber: PUBSubscriber) => new PUBSubscriber(subscriber));
	}
}

export class PUBSubscriber {
	subscriber_email_address!: string;
	subscriber_join_date!: string;
	campaign_type!: string;

	constructor(subscriber: PUBSubscriber) {
		Object.assign(this, subscriber);
	}
}

export class PUBSubscriberExportInfo {
	last_csv_export?: string;
	subscriber_count_since_last_csv_export?: number;

	constructor(subscriber: PUBSubscriberExportInfo) {
		Object.assign(this, subscriber);
	}
}